(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('legalEntity', ['UtilService', 'UsersService', '$q', '$translate', '$translatePartialLoader', function(UtilService, UsersService, $q, $translate, $translatePartialLoader) {
            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<select type='text' name='dhlLegalEntity'" +
                "ng-model='entity'" +
                "ng-change='vm.getNIPPattern()'" +
                "ng-options='entity.sourceName as entity.name for entity in entities'" +
                "class='form-control register-input normal-form'" +
                "autocomplete='off' id='dhlLegalEntity'>" +
                "</select>",
                link: function(scope, element, attributes, control) {

                    $translatePartialLoader.addPart('register');
                    $translate.refresh();
                }
            };
        }]);
})();
