(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('UsersService', UsersService);

    UsersService.$inject = ['$rootScope', '$http', 'UtilService', 'GenerateURL', 'Principal'];

    function UsersService($rootScope, $http, UtilService, GenerateURL, Principal) {
        var usersService = {
            activate: activate,
            activityReport: activityReport,
            addClient: addClient,
            changeRoles: changeRoles,
            checkNewUsersPasswordValid: checkNewUsersPasswordValid,
            checkPasswordExpireDays: checkPasswordExpireDays,
            checkPasswordResetLink: checkPasswordResetLink,
            create: create,
            createNewUser: createNewUser,
            deactivate: deactivate,
            delete: deleteUser,
            findByEmail: findByEmail,
            get: get,
            getDetails: getDetails,
            getEmailsLike: getEmailsLike,
            getUserDates: getUserDates,
            getFirstActivationDate: getFirstActivationDate,
            getLastActivityDate: getLastActivityDate,
            getLastFailedLoginDate: getLastFailedLoginDate,
            getLastFailLoginDate: getLastFailLoginDate,
            getLastSuccessfulLoginDate: getLastSuccessfulLoginDate,
            getOnePagePdfConfiguration: getOnePagePdfConfiguration,
            isEmailUnique: isEmailUnique,
            isEmailUniqueOtherSN: isEmailUniqueOtherSN,
            list: list,
            listWithParams: listWithParams,
            isLocked: isLocked,
            postChanges: postChanges,
            getLanguage: getLanguage,
            removeAllClients: removeAllClients,
            removeClient: removeClient,
            requestResetOwnPassword: requestResetOwnPassword,
            resetOwnPassword: resetOwnPassword,
            resetPassword: resetPassword,
            setOnePagePdf: setOnePagePdf,
            unlock: unlock,
            lock: lock,
            usersReport: usersReport,
            getActivityReportURL: getActivityReportURL,
            getUsersReportURL: getUsersReportURL
        };

        return usersService;

        function get(email) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/search/getByEmailContainingIgnoreCase',
                params: { email: email.toLowerCase() }
            });
        }

        function list(filter) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers',
                params: UtilService.nullsToEmptyStrings(filter)
            });
        }

        function listWithParams(filter) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/find' + Principal.getCurrentEntityCamelCase() + 'Users',
                params: UtilService.starsToPercents(UtilService.nullsToEmptyStrings(filter))
            });
        }

        function getEmailsLike(email) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/search/findByEmailContainingIgnoreCase',
                params: UtilService.starsToPercents(UtilService.nullsToEmptyStrings(email))
            });
        }

        function create(user) {
            return $http({
                method: 'PUT',
                url: $rootScope.API_URL + 'api/beneluxUsers/create',
                data: user
            });
        }

        function deleteUser(UUID) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + UUID + '/delete'
            });
        }

        function unlock(id) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + id + '/unlock'
            });
        }

        function lock(id) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + id + '/lock'
            });
        }

        function resetPassword(id) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + id + '/resetPassword'
            });
        }

        function activate(id) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + id + '/activate'
            });
        }

        function deactivate(id) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + id + '/deactivate'
            });
        }

        function changeRoles(id, roles) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + id + '/roles/change',
                data: { 'roleNames': roles }
            });
        }

        function usersReport(filter) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/reports/users',
                params: UtilService.starsToPercents(UtilService.nullsToEmptyStrings(filter)),
                responseType: 'arraybuffer'
            });
        }

        function activityReport(filter) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/reports/activity',
                params: UtilService.starsToPercents(UtilService.nullsToEmptyStrings(filter)),
                responseType: 'arraybuffer'
            });
        }

        function addClient(userId, email, clientNumber) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + userId + '/clients/add',
                params: { email: email, clientNumber: clientNumber }
            });
        }

        function removeClient(userId, email, clientNumber) {
            return $http({
                method: 'DELETE',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + userId + '/clients/remove',
                params: { email: email, clientNumber: clientNumber }
            });
        }

        function removeAllClients(userId, email) {
            return $http({
                method: 'DELETE',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + userId + '/clients/removeAll',
                params: { email: email }
            })
        }

        function findByEmail(email) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/byEmail/' + email.toLowerCase()
            });
        }

        function postChanges(userId, language) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + userId + '/editLanguage',
                params: { language: language }
            });
        }

        function getLanguage(userId) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + userId + '/language'
            });
        }

        function getLastFailLoginDate(name) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/ediPartyUsersByName/' + name
            });
        }

        function setOnePagePdf(userId, onePagePdf) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + userId + '/PdfPagesShow/edit',
                params: { onePagePdf: onePagePdf }
            });
        }

        function getOnePagePdfConfiguration(userId) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + userId + '/getUserOnePagePdfConfiguration'
            });
        }

        function createNewUser(registerData) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/registerRequest',
                data: registerData
            });
        }

        //checks uniqueness of email (user existence) in current source name
        function isEmailUnique(email) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/isUniqueEmail',
                params: { email: email.toLowerCase() }
            });
        }

        //checks uniqueness of email (user existence) in other source names (all except current)
        function isEmailUniqueOtherSN(email) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/isEmailUniqueOtherSN',
                params: { email: email.toLowerCase() }
            });
        }

        function getDetails(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/getFullDetails'
                    //params: { id: uuid }
            });
        }

        function getUserDates(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/dates'
            });
        }

        function getFirstActivationDate(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/firstActivationDate',
                //params: { id: uuid }
            });
        }

        function getLastActivityDate(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/lastActivityDate',
                //params: { id: uuid }
            });
        }

        function getLastFailedLoginDate(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/lastFailedLoginDate',
                //params: { id: uuid }
            });
        }

        function getLastSuccessfulLoginDate(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/lastSuccessfulLoginDate',
                params: { id: uuid }
            });
        }

        function requestResetOwnPassword(email) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/requestResetOwnPasswordRequest',
                params: { email: email.toLowerCase() }
            });
        }

        function checkPasswordExpireDays(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/checkPasswordExpireDays'
            });
        }

        function checkNewUsersPasswordValid(password) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/isPasswordValid',
                data: password
            });
        }

        function checkPasswordResetLink(uuid, link) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/setPassword/' + link
            });
        }

        function resetOwnPassword(uuid, link, password) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/setPassword/' + link,
                data: password
            });
        }

        function isLocked(uuid) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/' + uuid + '/lock'
            });
        }

        function getUsersReportURL(filter) {
            var params = UtilService.starsToPercents(UtilService.nullsToEmptyStrings(filter));
            var baseURL = $rootScope.API_URL + 'api/beneluxUsers/reports/users';

            return GenerateURL.generate(baseURL, params);
        }

        function getActivityReportURL(filter) {
            var params = UtilService.starsToPercents(UtilService.nullsToEmptyStrings(filter));
            var baseURL =  $rootScope.API_URL + 'api/beneluxUsers/reports/activity';

            return GenerateURL.generate(baseURL, params);
        }
    }
})();
