// NOT USED in Benelux, might be useful in future
(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('downloadController', downloadController);

    downloadController.$inject = ['$uibModalInstance', 'ediDocIds', 'language', 'DocumentsService'];

    function downloadController($uibModalInstance, ediDocIds, language, DocumentsService) {
        var vm = this;

        vm.data = {
            downloadPDF : true,
            downloadCSV : false,
            downloadCSVConcatenate : false,
            downloadExcel : false,
            downloadExcelConcatenate : false,
            downloadXML : false,
            ediDocIds : ediDocIds
        };
        vm.getDownloadURLs = getDownloadURLs;
        vm.checkboxClick = checkboxClick;

        vm.modal = {
            download: function() {
                $uibModalInstance.close();
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };

        function checkboxClick(fileType)
        {
            if(fileType === "XML") {
                vm.data.downloadXML = true;
                vm.data.downloadPDF = false;
                vm.data.downloadCSV = false;
                vm.data.downloadCSVConcatenate = false;
                vm.data.downloadExcel = false;
                vm.data.downloadExcelConcatenate = false;
            } else if(fileType === "ExcelConcatenate") {
                vm.data.downloadExcelConcatenate = true;
                vm.data.downloadPDF = false;
                vm.data.downloadCSV = false;
                vm.data.downloadCSVConcatenate = false;
                vm.data.downloadExcel = false;
                vm.data.downloadXML = false;
            }else if(fileType === "EXCEL") {
                vm.data.downloadExcel = true;
                vm.data.downloadPDF = false;
                vm.data.downloadCSV = false;
                vm.data.downloadCSVConcatenate = false;
                vm.data.downloadExcelConcatenate = false;
                vm.data.downloadXML = false;
            } else if(fileType === "CSVConcatenate") {
                vm.data.downloadCSVConcatenate = true;
                vm.data.downloadPDF = false;
                vm.data.downloadCSV = false;
                vm.data.downloadExcel = false;
                vm.data.downloadExcelConcatenate = false;
                vm.data.downloadXML = false;
            } else if(fileType === 'CSV') {
                vm.data.downloadCSV = true;
                vm.data.downloadPDF = false;
                vm.data.downloadCSVConcatenate = false;
                vm.data.downloadExcel = false;
                vm.data.downloadExcelConcatenate = false;
                vm.data.downloadXML = false;
            } else if(fileType === 'PDF') {
                vm.data.downloadPDF = true;
                vm.data.downloadCSV = false;
                vm.data.downloadCSVConcatenate = false;
                vm.data.downloadExcel = false;
                vm.data.downloadExcelConcatenate = false;
                vm.data.downloadXML = false;
            }
        }

        function getDownloadURLs(ediDocIds, downloadPDF, downloadCSV,downloadCSVConcatenate,downloadExcel,downloadExcelConcatenate,downloadXML){
            if(downloadPDF) {
                return getAsPdf(ediDocIds);
            } else if (downloadCSV) {
                return getCsvs(ediDocIds);
            } else if (downloadCSVConcatenate) {
                return getCsvsConcatenate(ediDocIds);
            } else if (downloadExcel) {
                return getExcel(ediDocIds)
            }else if (downloadExcelConcatenate) {
                return getExcelConcatenate(ediDocIds)
            } else if (downloadXML) {
                return getXML(ediDocIds)
            }
        }

        function getAsPdf(ediDocIds){
            if(ediDocIds.length > 1){
                return DocumentsService.getPdfsURL(ediDocIds, language);
            } else {
                return DocumentsService.getPdfURL(ediDocIds, true, null);
            }
        }

        function getCsvs(ediDocIds){
            if(ediDocIds.length > 1){
                return DocumentsService.getCsvsURL(ediDocIds, language);
            } else {
                return DocumentsService.getInvoiceCsvURL(ediDocIds[0]);
            }
        }

        function getCsvsConcatenate(ediDocIds){
            if(ediDocIds.length > 1){
                return DocumentsService.getCsvsConcatenateURL(ediDocIds, language)
            }
        }

        function getExcel(ediDocIds){
            if(ediDocIds.length > 1){
                return DocumentsService.getExcelURL(ediDocIds, language);
            } else {
                return DocumentsService.getInvoiceXlsxURL(ediDocIds[0]);
            }
        }
        function getExcelConcatenate(ediDocIds){
            if(ediDocIds.length > 1){
                return DocumentsService.getExcelConcatenateURL(ediDocIds, language);
            }
        }

        function getXML(ediDocIds){
            if(ediDocIds.length > 1){
                return DocumentsService.getXMLURL(ediDocIds, language);
            } else {
                return DocumentsService.getXMLURL(ediDocIds[0]);
            }
        }
    }
})();
