(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('ShellService', ShellService);

	ShellService.$inject = ['$rootScope','$http'];

    function ShellService ($rootScope, $http) {

		return {
			'logout': function(){
				return $http({
				  method: 'POST',
				  url: $rootScope.API_URL + 'api/auth/logout'
				});
			}
		};
	}
})();
