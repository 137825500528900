(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('resendController', resendController);

    resendController.$inject = ['$uibModalInstance'];

    function resendController($uibModalInstance) {
        var vm = this;
        vm.resendSelect = 'same';
        vm.data = {
            email: null
        };
        vm.emailValidated = false;

        vm.validateEmail = validateEmail;

        vm.modal = {
            send: function() {
                vm.result = vm.resendSelect;
                $uibModalInstance.close(vm.data);
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };
        function validateEmail(value){
            vm.emailValidated = true;
            var emailArray = value.split(";");
            emailArray.forEach(function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(email)){
                    vm.emailValidated = false;
                }
            });

        }
    }
})();
