(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account', '$rootScope', '$http'];

    function Principal($q, Account, $rootScope, $http) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            getProperty: getProperty,
            hasInvoiceRead: hasInvoiceRead,
            hasClientRead: hasClientRead,
            hasUserRead: hasUserRead,
            hasInvoiceWrite: hasInvoiceWrite,
            hasClientWrite: hasClientWrite,
            hasUserWrite: hasUserWrite,
            hasPortalAccess: hasPortalAccess,
            hasAllDocumentsAccess: hasAllDocumentsAccess,
            hasSystemAccess: hasSystemAccess,
            hasTemplateAccess: hasTemplateAccess,
            updateLanguage: updateLanguage,
            getLegalEntities: getLegalEntities,
            getCurrentEntity: getCurrentEntity,
            getCurrentEntityCamelCase: getCurrentEntityCamelCase,
            getCurrentEntityLowerCase: getCurrentEntityLowerCase,
            getLockedLegalEntities: getLockedLegalEntities
        };

        var currentEntity = '';

        return service;

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function() {
                return false;
            });
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get()
                .then(function(response) {
                    getAccountThen(response);
                })
                .catch(function(response) {
                    getAccountCatch(response);
                });

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account.data;
                _authenticated = true;
                currentEntity = getProperty('currentSourceName');
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                currentEntity = '';
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }

        function getProperty(property) {
            if (!property) {
                return null;
            }

            if (angular.isDefined(_identity) && _identity != null) {
                return _identity[property];
            }

            return null;
        }

        function getAuthorities() {
            var authorities = getProperty('authorities');
            if (authorities == null) {
                authorities = [];
            }
            return authorities;
        }

        function getLegalEntities() {
            var entities = getProperty('sourceNames');
            if (entities == null) {
                entities = "";
            }
            return entities;
        }

        function getLockedLegalEntities() {
            var entitiesLocked = getProperty('sourceNamesLocked');
            if (entitiesLocked == null) {
                entitiesLocked = "";
            }
            return entitiesLocked;
        }

        function getCurrentEntity() {
            return currentEntity;
        }

        function getCurrentEntityCamelCase() {
            return currentEntity.charAt(0) + currentEntity.charAt(1).toLowerCase();
        }

        function getCurrentEntityLowerCase() {
            return currentEntity.toLowerCase();
        }

        function hasInvoiceRead() {
            return getAuthorities().indexOf('ROLE_INVOICE_READ@' + currentEntity) >= 0;
        }

        function hasClientRead() {
            return getAuthorities().indexOf('ROLE_CLIENT_READ@' + currentEntity) >= 0;
        }

        function hasUserRead() {
            return getAuthorities().indexOf('ROLE_USER_READ@' + currentEntity) >= 0;
        }

        function hasInvoiceWrite() {
            return getAuthorities().indexOf('ROLE_INVOICE_WRITE@' + currentEntity) >= 0;
        }

        function hasClientWrite() {
            return getAuthorities().indexOf('ROLE_CLIENT_WRITE@' + currentEntity) >= 0;
        }

        function hasUserWrite() {
            return getAuthorities().indexOf('ROLE_USER_WRITE@' + currentEntity) >= 0;
        }

        function hasPortalAccess() {
            return getAuthorities().indexOf('ROLE_PORTAL_ACCESS@' + currentEntity) >= 0;
        }

        function hasAllDocumentsAccess() {
            return getAuthorities().indexOf('ROLE_ALL_DOCUMENTS_ACCESS@' + currentEntity) >= 0;
        }

        function hasSystemAccess() {
            return getAuthorities().indexOf('ROLE_SYSTEM_ACCESS@' + currentEntity) >= 0;
        }

        function hasTemplateAccess() {
            return getAuthorities().indexOf('ROLE_TEMPLATE_ACCESS@' + currentEntity) >= 0;
        }

        function updateLanguage(language) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/language',
                data: language
            });
        }
    }
})();
