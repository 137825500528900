(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('fileUpload', function() {
            return {
                require: 'ngModel',
                restrict: 'A',
                scope: {
                    fileUpload: '&'
                },
                link: function(scope, element, attributes, control) {
                    element.on('change', onChange);
                    scope.$on('destroy', function () {
                        element.off('change', onChange);
                    });
                    function onChange() {
                        control.$setViewValue(element[0].files[0]);
                        scope.$apply(function () {
                            scope.fileUpload();
                        });
                    }
                }
            };
        });
})();
