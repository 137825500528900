(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('nip', ['UtilService', 'UsersService', '$q', '$translate', '$translatePartialLoader', function(UtilService, UsersService, $q, $translate, $translatePartialLoader) {

            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='text' id='dhlNip' name='dhlNip' ng-pattern='vm.pattern' maxlength='{{vm.patternMaxLength}}' minlength='{{vm.patternMinLength}}' class='form-control register-input normal-form'ng-model='vm.data.nip' uib-tooltip={{nipTooltip()}} tooltip-placement='top' required='required' ng-class='registerNip' />",
                link: function(scope, element, attributes, control) {
                    $translatePartialLoader.addPart('register');
                    $translate.refresh();
                    //Required
                    control.$validators.required = jQuery.proxy(function(value) {
                        return (!control.$isEmpty(value));
                    }, element);

                    //allow only digits
                    control.$parsers.push(function(val) {
                        if (angular.isUndefined(val)) {
                            var val = '';
                        }
                        var clean = val.replace(/[^0-9]+/g, '');
                        if (val !== clean) {
                            control.$setViewValue(clean);
                            control.$render();
                        }
                        return clean;
                    });
                    
                    //ignore space
                    $(element).bind('keypress', function(event) {
                        if (event.keyCode === 32) {
                            event.preventDefault();
                        }
                    });

                    scope.nipTooltip = function() {
                        var tooltipNip = '';
                        if (control.$error.maximum || control.$error.minimum || control.$error.validDigits)
                            tooltipNip = $translate.instant('directives.requiredNip');
                        else if (control.$error.required)
                            tooltipNip = $translate.instant('directives.requiredNip');

                        return tooltipNip;
                    };

                    $(element).on('keyup change', function(event) {
                        if ($("#dhlNip").hasClass("ng-invalid"))
                            $("#dhlNipLabel").addClass("red");
                        else
                            $("#dhlNipLabel").removeClass("red");
                    });
                }
            }
        }]);
})();
