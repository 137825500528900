(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('NewClientController', NewClientController);

    NewClientController.$inject = ['$uibModalInstance', '$scope', 'UsersService', 'uuid4', 'ClientsService', '$translate', 'Principal', 'UtilService'];

    function NewClientController($uibModalInstance, $scope, UsersService, UUID, ClientsService, $translate, Principal, UtilService) {
        var vm = this;
        vm.linkXlsx = $translate.instant("clients.newClient.linkXlsx");
        vm.pdfXlsx = $translate.instant("clients.newClient.pdfXlsx");
        vm.pdfCsv = $translate.instant("clients.newClient.pdfCsv");
        vm.newClientEInvoiceYes = $translate.instant("clients.newClient.yes");
        vm.newClientEInvoiceNo = $translate.instant("clients.newClient.no");

        vm.patternMinLength = null;
        vm.patternMaxLength = null;
        vm.pattern = null;
        getNIPPattern();

        vm.data = {
            ediClientId: UUID.generate(),
            clientNumber: "",
            payerNumber: "",
            clientName: "",
            users: [],
            clients: "",
            eInvoiceAgreement: "true",
            portalLang: "EN",
            docSending: "LINK",
            // correctingDocSending: "LINK",
            nip: "",
            contactPerson: "",
            phoneNumber: "",
            notes: "",
            duplicateEmailError: null
        };

        function getNIPPattern() {
            // var NIP = UtilService.getNIPPattern(Principal.getCurrentEntity());
            var NIP = UtilService.getNIPPattern();
            vm.patternMinLength = NIP.minlength;
            vm.patternMaxLength = NIP.maxlength;
            vm.pattern = new RegExp(NIP.regex);
        }

        vm.user = {
            getByEmail: function(email) {
                return UsersService.getEmailsLike({ email: email })
                    .then(function(response) {
                        return response.data._embedded.ediUsers.map(function(user) {
                            return user.email;
                        });
                    });
            },
            add: function() {
                if (vm.data.users.map(function(user) {
                    return user.email;
                }).indexOf(vm.data.user) === -1) {
                    if (angular.isDefined(vm.data.user) && vm.data.user != "" && vm.data.user != null) {
                        vm.data.users.push({ email: vm.data.user });
                        vm.data.user = "";
                    }
                } else {
                    vm.data.duplicateEmailError = $translate.instant("clients.client.duplicateEmail");
                }
            },
            delete: function() {
                var index = vm.data.users.map(function(user) {
                    return user.email;
                }).indexOf(vm.data.user);

                if (index !== -1) {
                    vm.data.users.splice(index, 1);
                    vm.data.user = "";
                }
            }
        };

        $scope.$watch('vm.data.nip', function() {
            vm.data.nip = vm.data.nip.replace(/\D/g, '');
        });

        $scope.$watch('vm.data.payerNumber', function() {
            vm.data.payerNumber = vm.data.payerNumber.replace(/\D/g, '');
        });

        $scope.$watch('vm.data.clientNumber', function() {
            vm.data.clientNumber = vm.data.clientNumber.replace(/\D/g, '');
        });

        //Define a watch for user list select
        $scope.$watch('vm.selection', function(newValue) {
            if (newValue != null)
                vm.data.user = newValue[0].email;
        });

        vm.modal = {
            clientCheck: function() {
                if ((vm.data.clientNumber + '').length === 8) {
                    ClientsService.findByClientNumber(vm.data.clientNumber).then(function(response) {
                        if (response.data != null) {
                            vm.data.clients = vm.data.clientNumber;
                        } else {
                            vm.data.clients = '';
                        }

                    }).catch(function(response) {
                        vm.data.clients = '';
                    });
                } else {
                    vm.data.clients = '';
                }
            },
            add: function() {
                vm.data.nip = vm.data.nip.replace(/\D/g, '');
                $uibModalInstance.close(vm.data);
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };
    }
})();
