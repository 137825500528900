(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('RegisterController', RegisterController);

    RegisterController.$inject = ['$scope', '$state', '$stateParams', 'UsersService', '$animate', 'uuid4', '$sce', '$translate', 'UtilService', '$rootScope', 'API_URL'];

    function RegisterController($scope, $state, $stateParams, UsersService, $animate, uuid4, $sce, $translate, UtilService, $rootScope, API_URL) {
        var vm = this;
        vm.translate = $translate.instant;
        $scope.invoiceNumberTooltip = $sce.trustAsHtml($translate.instant('register.invoiceNumberTooltip'));
        $scope.entities = [{party: 'IPVN', sourceName: 'NL', name: $translate.instant('global.legalentities.NL')},
                           {party: 'IPVB', sourceName: 'BE', name: $translate.instant('global.legalentities.BE')},
                           {party: 'IPSN', sourceName: 'EC', name: $translate.instant('global.legalentities.EC')}];
        $scope.entity = $scope.entities[0].sourceName;

        //Declare variables
        vm.filter = null;
        vm.data = null;
        vm.data = {
            uuid: null,
            email: null,
            nip: null,
            invoiceNr: null,
            totalAmount: null,
            clientCode: null
        };
        vm.uuid = null;
        vm.patternMinLength = null;
        vm.patternMaxLength = null;
        vm.pattern = null;

        //Declare functions
        vm.init = init;
        vm.getNIPPattern = getNIPPattern;
        vm.getInvoiceAcceptanceLink = getInvoiceAcceptanceLink;
        vm.send = send;
        vm.invalid = invalid;

        //Init functions
        function init() {
            getNIPPattern();
            vm.data.uuid = uuid4.generate();
        }

        function getNIPPattern() {
            // var NIP = UtilService.getNIPPattern($scope.entity); //if dependent on source name (legal entity)
            var NIP = UtilService.getNIPPattern();
            vm.patternMinLength = NIP.minlength;
            vm.patternMaxLength = NIP.maxlength;
            vm.pattern = new RegExp(NIP.regex);
        }

        function getInvoiceAcceptanceLink() {
            if ($rootScope.API_URL === API_URL.BE) {
                return 'https://www.dhl.com/be-nl/home.html';
            } else return 'https://www.dhl.com/nl-nl/home.html';
        }

        function send() {
            vm.registerErrorText = null;

            var registerData = {
                uuid: vm.data.uuid,
                email: vm.data.email,
                nip: vm.data.nip,
                invoiceNumber: vm.data.invoiceNr,
                grossAmount: parseFloat(vm.data.totalAmount.replace(',', '.')),
                clientCode: vm.data.clientCode,
                sourceName: $scope.entity
            };
            UsersService.createNewUser(registerData).then(function(response) {
                $state.go('register_end');
            }).catch(function(response) {
            });
        }

        function invalid() {
            vm.registerErrorText=$translate.instant("register.registerErrorText");
            if($( "#dhlEmail" ).hasClass( "ng-invalid" ))
                $( "#dhlEmailLabel" ).addClass("red");
            else
                $( "#dhlEmailLabel" ).removeClass("red");

            if($( "#dhlNip" ).hasClass( "ng-invalid" ))
                $( "#dhlNipLabel" ).addClass("red");
            else
                $( "#dhlNipLabel" ).removeClass("red");

            if($( "#dhlInvoice" ).hasClass( "ng-invalid" ))
                $( "#dhlInvoicedhlLabel" ).addClass("red");
            else
                $( "#dhlInvoicedhlLabel" ).removeClass("red");

            if($( "#dhlGrossAmount" ).hasClass( "ng-invalid" ))
                $( "#dhlGrossAmountLabel" ).addClass("red");
            else
                $( "#dhlGrossAmountLabel" ).removeClass("red");

            if($( "#dhlClientCode" ).hasClass( "ng-invalid" ))
                $( "#dhlClientCodeLabel" ).addClass("red");
            else
                $( "#dhlClientCodeLabel" ).removeClass("red");
        }

        function getUrl(translation) {
            return "Some test email just for testing controller";
            // return $translate.instant("registerEnd." + translation + "." + $rootScope.API_DOMAIN);
        }

        //Init controller
        vm.init();
    }
})();
