(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('usersPanel', {
                parent: 'application',
                url: '/usersPanel',
                views: {
                    'content@application': {
                        templateUrl: 'app/views/users/usersPanel.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    	$translatePartialLoader.addPart('documents');
                    	$translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('users');
                        return $translate.refresh();
                    }]
                },
                redirectTo: 'users',
            })
            .state('users', {
                parent: 'usersPanel',
                url: '/users?size&page&email&clientNumber&clientName',
                params: {
                    size: '25',
                    page: '0',
                    email: null,
                    clientNumber: null,
                    clientName: null
                },
                data: {
                    pageTitle: 'global.menu.users'
                },
                views: {
                    'innerContent@usersPanel': {
                        templateUrl: 'app/views/users/users/users.html',
                        controller: 'UsersController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    	$translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('users');
                        return $translate.refresh();
                    }]
                }
            })
            .state('user', {
                parent: 'usersPanel',
                url: '/user?email',
                params: {
                    email: null
                },
                data: {
                    pageTitle: 'global.menu.user'
                },
                views: {
                    'innerContent@usersPanel': {
                        templateUrl: 'app/views/users/user/user.html',
                        controller: 'UserController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    	$translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('users');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
