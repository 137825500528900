(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', 'Auth', '$uibModal', '$translate', 'UsersService', 'Principal', 'AlertService'];

    function LoginController($rootScope, $state, Auth, $uibModal, $translate, UsersService, Principal, AlertService) {
        var vm = this;

        //Declare functions
        vm.login = login;
        vm.logUser = logUser;
        vm.loginreq = loginreq;
        vm.pwdreq = pwdreq;

		// Currently not used
		// AlertService.success(function(){
		//     return $translate.instant('login.migration')
		// });
        function loginreq() {
            return $translate.instant('login.loginreq');
        }

        function pwdreq() {
            return $translate.instant('login.pwdreq');
        }

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username + '_BENELUX',
                password: vm.password
            }).then(function(response) {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');
                if (response.data.show == true) {
                    $state.go('password-expire', { days: response.data.days });
                } else {
                    if (angular.isDefined(Auth.getPreviousState())) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    } else {
                        $state.go('documents');
                    }
                }
            }).catch(function(response) {
                vm.authenticationError = true;
                switch (response.status) {
                    case 401:
                        Auth.isBlocked(vm.username)
                            .then(function(response) {
                                if (response.data.parameter1) {
                                    //account blocked due to too many failed logins
                                    AlertService.error($translate.instant('login.10times'));
                                /*
                                Alert below is revealing username existence - showed even when wrong password was given
                                Temporarly disabled until login mechanism will be refactored
                                */
                                // } else if (response.data.parameter2) {
                                    //password has expired
                                    // AlertService.error($translate.instant('login.expired'));
                                } else {
                                    //user does not exist
                                    AlertService.error($translate.instant('login.incorrect'));
                                }
                            }).catch(function(response) {
                                AlertService.error($translate.instant('login.incorrect'));
                            });
                        break;
                    case 403:
                        AlertService.error($translate.instant('login.auth'));
                        break;
                    case 404:
                        AlertService.error($translate.instant('login.notfound'));
                        break;
                    case 408:
                        AlertService.error($translate.instant('login.timeout'));
                        break;
                    case 500:
                        AlertService.error($translate.instant('login.internal'));
                        break;
                    default:
                        AlertService.error($translate.instant('login.failure'));
                }
            });
        }

        function logUser() {
            $rootScope.$broadcast('authenticationSuccess');
            if (angular.isDefined(Auth.getPreviousState())) {
                var previousState = Auth.getPreviousState();
                Auth.resetPreviousState();
                $state.go(previousState.name, previousState.params);
            } else {
                $state.go('documents');
            }
        }
    }
})();
