(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('client', ['UtilService', 'UsersService', '$q', '$translate', '$translatePartialLoader', function(UtilService, UsersService, $q, $translate, $translatePartialLoader) {
            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='text' ng-pattern='/^[0-9]+$/' smart-float class='form-control register-input normal-form' autocomplete='off' id='dhlClientCode' uib-tooltip='{{clientTooltip()}}'' tabindex=0 ng-model='vm.data.clientCode' ng-maxlength='128'></input>",
                link: function(scope, element, attributes, control) {
                    $translatePartialLoader.addPart('register');
                    $translate.refresh();
                    //Validate minimum
                    control.$validators.minimum = jQuery.proxy(function(value) {
                        var minimum = 8;
                        if (UtilService.isFilled($(this).attr('data-min')))
                            minimum = $(this).attr('data-min');
                        return (control.$isEmpty(value) || (value.length >= minimum));
                    }, element);
                    //Validate maximum
                    control.$validators.maximum = jQuery.proxy(function(value) {
                        var maximum = 8;
                        if (UtilService.isFilled($(this).attr('data-max')))
                            maximum = $(this).attr('data-max');
                        return (control.$isEmpty(value) || (value.length <= maximum));
                    }, element);

                    control.$parsers.push(function(val) {
                        if (angular.isUndefined(val)) {
                            var val = '';
                        }
                        var clean = val.replace(/[^0-9]+/g, '');
                        if (val !== clean) {
                            control.$setViewValue(clean);
                            control.$render();
                        }
                        return clean;
                    });

                    $(element).bind('keypress', function(event) {
                        if (event.keyCode === 32) {
                            event.preventDefault();
                        }
                    });

                    scope.clientTooltip = function() {
                        return scope.$conditionalTranslate(control.$error.minimum || control.$error.maximum, "directives.giveValidClientNumber");
                    };

                    // Live validation tooltip
                    $(element).on('keyup change ', function(event) {
                        if ($("#dhlClientCode").hasClass("ng-invalid"))
                            $("#dhlClientCodeLabel").addClass("red");
                        else
                            $("#dhlClientCodeLabel").removeClass("red");
                    });
                }
            }
        }]);
})();
