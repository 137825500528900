(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('demail', ['UtilService', '$translate', '$translatePartialLoader', function(UtilService, $translate, $translatePartialLoader) {

            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='text' id='email' name='email' class='form-control dispute-input normal-form' " +
                    "ng-model='vm.data.email' autocomplete='off' tooltip-placement='top' " +
                    "uib-tooltip={{emailTooltip()}} tooltip-placement='top' ng-change='vm.toggleRetypeEmailField()'/>",
                link: function(scope, element, attributes, control) {
                    $translatePartialLoader.addPart('register');
                    $translate.refresh();
                    // Required
                    control.$validators.required = jQuery.proxy(function(value) {
                        return (!control.$isEmpty(value));
                    }, element);

                    // Validate maximum
                    control.$validators.max = jQuery.proxy(function(value) {
                        var maximum = 256;
                        if (UtilService.isFilled($(this).attr('data-max')))
                            maximum = $(this).attr('data-max');
                        return (control.$isEmpty(value) || (value.length <= maximum));
                    }, element);

                    // Validate email
                    control.$validators.email = jQuery.proxy(function(value) {
                        return (control.$isEmpty(value) || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value));
                    }, element);

                    scope.emailTooltip = function() {
                        var tooltip = '';
                        if (control.$error.max)
                            tooltip += $translate.instant("directives.maxEmail1") + ($(this).attr('data-max') || 256) + $translate.instant("directives.maxEmail2");
                        if (control.$error.email)
                            tooltip += $translate.instant("directives.requiredEmail");
                        else if (control.$error.required)
                            tooltip += $translate.instant("directives.giveValidEmail");

                        return tooltip;
                    }

                    $(element).on('keyup change ', function(event) {
                        if ($("#dhlEmail").hasClass("ng-invalid"))
                            $("#dhlEmailLabel").addClass("red")
                        else
                            $("#dhlEmailLabel").removeClass("red")
                    });
                }
            }
        }]);
})();
