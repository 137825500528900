(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('RegisterEndController', RegisterEndController);

    RegisterEndController.$inject = ['$scope', '$state', '$stateParams', 'UsersService', '$animate', 'uuid4', '$sce','$translate', 'UtilService', '$rootScope', '$translate'];

    function RegisterEndController($scope, $state, $stateParams, UsersService, $animate, uuid4, $sce, $translate, UtilService, $rootScope) {
        var vm = this;
        vm.translate = $translate.instant;

        //Declare variables
        vm.filter = null;
        vm.data = null;
        vm.data = {
            uuid: null,
            email: null,
            nip: null,
            invoiceNr: null,
            totalAmount: null,
            clientCode: null
        };
        vm.uuid = null;
        vm.patternMinLength = null;
        vm.patternMaxLength = null;
        vm.pattern = null;

        vm.getUrl = getUrl;

        //Declare functions
        vm.init = init;

        //Init functions
        function init() {
            vm.data.uuid = uuid4.generate();
        }

        function getUrl(translation) {
            return $translate.instant("registerEnd." + translation + "." + $rootScope.API_DOMAIN);
        }

        //Init controller
        vm.init();
    }
})();
