(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('ClientsService', ClientsService);

    ClientsService.$inject = ['$rootScope', '$http', 'UtilService', 'Principal'];

    function ClientsService($rootScope, $http, UtilService, Principal) {
        var service = {
            create: create,
            findByClientNumber: findByClientNumber,
            getClientTemplates: getClientTemplates,
            list: list,
            listByUserEmail: listByUserEmail,
            listWithParams: listWithParams,
            manage: manage,
            parseManagementFile: parseManagementFile,
            postClientTemplates: postClientTemplates,
            update: update,
            updateFtpConfig: updateFtpConfig,
            deleteFtpConfig: deleteFtpConfig
        };

        return service;

        function list(filter) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxClients',
                params: filter
            });
        }

        function listWithParams(filter) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxClients/find' + Principal.getCurrentEntityCamelCase() + 'Clients',
                params: UtilService.starsToPercents(UtilService.nullsToEmptyStrings(filter))
            });
        }

        function create(client) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxClients/create',
                data: UtilService.emptyStringsToNulls(client)
            });
        }

        function update(client) {
            return $http({
                method: 'PUT',
                url: $rootScope.API_URL + 'api/beneluxClients/update',
                data: client
            });
        }

        function listByUserEmail(filter) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxClients/search/findAllWithClientsByEmail',
                params: filter
            });
        }

        function findByClientNumber(clientNumber) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/' + Principal.getCurrentEntityLowerCase() + 'Clients/search/findByClientNumber',
                params: { 'clientNumber': clientNumber }
            });
        }

        function parseManagementFile(file) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxClients/manage/parse',
                data: file
            });
        }

        function manage(actions) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxClients/manage',
                data: actions
            });
        }

        function getClientTemplates(ediClientId) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxClients/' + ediClientId + '/getTemplatesId'
            });
        }

        function postClientTemplates(ediClientId, templates) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxClients/' + ediClientId + '/postTemplates',
                data: templates
            });
        }

        function updateFtpConfig(client) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxClients/updateFtpConfig',
                data: client
            });
        }

        function deleteFtpConfig(client) {
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxClients/deleteFtpConfig',
                data: client
            });
        }
    }
})();
