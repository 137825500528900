(function() {
    'use strict';

    var portalAlert = {
        template: '<span class="container container-fluid" style="padding:0;" ng-show="vm.alerts.length > 0" ng-cloak="">' +
            '<div ng-repeat="alert in vm.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
            '<uib-alert  type="{{alert.type}}" close="alert.close(vm.alerts); vm.onClose(alert)">{{vm.get(alert.msg)}}</uib-alert>' +
            '</div>' +
            '</span>',
        controller: portalAlertController,
        controllerAs: 'vm'
    };

    angular
        .module('edistradadhluiApp')
        .component('portalAlert', portalAlert);

    portalAlertController.$inject = ['$rootScope', '$scope', '$timeout', 'AlertService', '$sce'];

    function portalAlertController($rootScope, $scope, $timeout, AlertService, $sce) {
        var vm = this;

        vm.get = function(functionOrTranslatedString) {
            if (functionOrTranslatedString instanceof Function)
                return $sce.trustAsHtml(functionOrTranslatedString());
            else
                return functionOrTranslatedString;
        }

        vm.onClose = function(alert) {
            setTimeout(function() {
                $('#under-fixed').css('padding-top', $('#fixed').height());
                $(window).trigger('scroll');
            }, 100);

        }


        vm.alerts = AlertService.get();

        $scope.$watch('vm.alerts', function(newValue, oldValue) {
            $timeout(function() { $rootScope.$broadcast('alert-service-event'); }, 0);
        }, true);
        $scope.$on('$destroy', function() {
            vm.alerts = [];
            AlertService.clear();
        });
    }
})();
