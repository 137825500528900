(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('site', {
                abstract: true,
                views: {
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html'
                    },
                    'shell@': {
                        templateUrl: 'app/layouts/shell/shell.html',
                        controller: 'ShellController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                    }]
                }

            })
            .state('application', {
                parent: 'site',
                abstract: true,
                url: '/app',
                views: {
                    'content@': {
                        templateUrl: 'app/layouts/application/application.html'
                    }
                },
                resolve: {
                    authorize: ['Auth',
                        function (Auth) {
                            return Auth.authorize();
                        }
                    ]
                }
            });
    }
})();
