(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('PdfViewService', PdfViewService);

    PdfViewService.$inject = ['$rootScope', '$http'];

    function PdfViewService($rootScope, $http) {
        var service = {
            getFirstPagePdf: getFirstPagePdf,
            getInvoiceStylesheet: getInvoiceStylesheet
        };

        return service;

        function getInvoiceStylesheet(EdiDocId) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxInvoices/' + EdiDocId + '/getInvoiceStylesheet'
            });
        }

        function getFirstPagePdf(EdiDocId) {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxInvoices/' + EdiDocId + '/getFirstPagePdf',
                responseType: 'arraybuffer'
            });
        }
    }
})();
