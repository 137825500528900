/* eslint-disable indent */
(function() {
	'use strict';

	angular.
		module('edistradadhluiApp')
		.controller('ShellController', ShellController);

	ShellController.$inject = [ '$rootScope', '$state', 'Auth',
			'Principal', 'EntityService', 'SHELL_LOGO_HREF', 'AlertService', '$translate' ];

	function ShellController($rootScope, $state, Auth, Principal, EntityService, SHELL_LOGO_HREF, AlertService, $translate) {
		var vm = this;
		vm.state = $state;

		vm.logoHref = SHELL_LOGO_HREF;
		// Declare function
		vm.setVersion = setVersion;
		vm.toggleSidebar = toggleSidebar;
		vm.showSidebar = showSidebar;
		vm.hideSidebar = hideSidebar;
		vm.logout = logout;
		vm.identity = Principal.identity();
		vm.changeEntity = changeEntity;
		vm.currentEntity = Principal.getCurrentEntity();
		vm.getLegalEntities = getLegalEntities;
		vm.entities = Principal.getLegalEntities().split(',');
		vm.blockedEntities = Principal.getLockedLegalEntities().split(',');

		function filterEntities() {
			if(vm.blockedEntities != null && vm.blockedEntities != "" && vm.entities != null) {
				vm.blockedEntities.forEach(function(entity) {
					var index = vm.entities.indexOf(entity);
					if (index !== -1)
						vm.entities.splice(index, 1);
				});
			}
		}

		function changeEntity(entity) {
			if(entity !== vm.currentEntity) {
				EntityService.changeEntity(entity).then(function(response) {
					getLegalEntities();
					$state.reload();
					if(response.data.message !== "SUCCESS")
						AlertService.error($translate.instant("error.occurred"));
				})
				.catch(function() {
					$state.go('documents');
					AlertService.error($translate.instant("error.occurred"));
				});
			}
		}

        function setVersion() {
        var version_text = $('#version_text');
        var version = "256";
        console.log("setVersion2");
                EntityService.getVersion().then(function(response) {
                    version = $rootScope.VERSION;
                    version += " | ";
                    version += response.data.version;
                    version_text.text(version);
                })
                .catch(function(response) {
                    version ="error";
                    version_text.text(version);
                });
        }

		function getLegalEntities() {
		    setVersion();
			vm.currentEntity = Principal.getCurrentEntity();
			vm.entities = Principal.getLegalEntities().split(',');
			vm.blockedEntities = Principal.getLockedLegalEntities().split(',');
			filterEntities();
		}

		function showSidebar() {
			var toggle = $('.navbar-toggle');
			setTimeout(function() {
				toggle.addClass('toggled');
			}, 430);

			var div = '<div id="bodyClick"></div>';
			$(div).appendTo("body").click(function() {
				hideSidebar();
				var $body = angular.element(document.body);
				var $rootScope = $body.injector().get('$rootScope');
				$rootScope.$apply(function() {
					$rootScope.sidebar = false;
				});
			});

			$('html').addClass('nav-open');
		}

		function hideSidebar() {
			var toggle = $('.navbar-toggle');
			$('html').removeClass('nav-open');
			$('#bodyClick').remove();
			setTimeout(function() {
				toggle.removeClass('toggled');
			}, 400);
		}

		function toggleSidebar() {
			var navbar = $('.navbar-collapse');
			if ($rootScope.sidebar == undefined) {
				$rootScope.sidebar = true;
				navbar.css('min-height', window.screen.height);
			} else {
				$rootScope.sidebar = !$rootScope.sidebar;
			}

			if ($rootScope.sidebar == false) {
				hideSidebar();
			} else {
				showSidebar();
			}
		}

		function logout() {
			Auth.logout(); // Logout through HTTP request
			$state.go('documents'); // Go to login view
		}
	}
})();
