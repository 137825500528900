(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('PendingCasesService', PendingCasesService);

    PendingCasesService.$inject = ['$rootScope', '$http'];

    function PendingCasesService ($rootScope, $http) {
        var service = {
//            getDocument : getDocument,
            getDocumentsWithoutClient: getDocumentsWithoutClient,
            getDocumentsOfDebtors: getDocumentsOfDebtors,
            getNameByClientNumber : getNameByClientNumber,
            getUsers : getUsers,
            usersAccepted : usersAccepted,
            usersDenied : usersDenied,
            documentsWithoutClientAccepted : documentsWithoutClientAccepted,
            documentsWithoutClientDenied : documentsWithoutClientDenied,
            documentsOfDebtorsAccepted: documentsOfDebtorsAccepted,
            documentsOfDebtorsDenied: documentsOfDebtorsDenied
        };

        return service;
        function getNameByClientNumber(SapNumber){
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxClients/getName',
                params : {'clientNumber': SapNumber}
            });
        }
        function getDocumentsWithoutClient(){
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxInvoices/clientWaitInvoices'
            });
        }
        function getDocumentsOfDebtors(){
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxInvoices/onHoldInvoices'
            });
        }
        function getUsers(){
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/beneluxUsers/notActive'
            });
        }
        function usersAccepted(UIDs){
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/acceptAll',
                data : UIDs //byc moze nie zalapie nazwy
            });
        }
        function usersDenied(UIDs){
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxUsers/rejectAll',
                data : UIDs
            });
        }
        function documentsWithoutClientAccepted(ediDocIds){
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxInvoices/approveWait',
                data: ediDocIds
            });
        }
        function documentsWithoutClientDenied(ediDocIds){
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxInvoices/rejectWait',
                data : ediDocIds
            });
        }
        function documentsOfDebtorsAccepted(ediDocIds){
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxInvoices/approveOnHold',
                data: ediDocIds
            });
        }
        function documentsOfDebtorsDenied(ediDocIds){
            return $http({
                method: 'POST',
                url: $rootScope.API_URL + 'api/beneluxInvoices/rejectOnHold',
                data : ediDocIds
            });
        }
//        function getDocument(ediDocId){
//            return $http({
//                method: 'GET',
//                url: $rootScope.API_URL + 'api/beneluxInvoices/' + ediDocId + '/getPdf',
//                responseType: 'arraybuffer'
//            });
//        }

    }
})();
