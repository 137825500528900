(function() {
    'use strict';

    angular
		.module('edistradadhluiApp')
		.controller('EditRoleController', EditRoleController);

    EditRoleController.$inject = ['$uibModalInstance', 'RolesService', 'passRole', 'AlertService', '$translate'];

    function EditRoleController($uibModalInstance, RolesService, passRole, AlertService, $translate) {
        var vm = this;

        vm.allPermissions = [];
        vm.newRole = {
            ediRoleId:passRole.ediRoleId,
            description:passRole.description,
            roleName:passRole.roleName,
            sourceName:'DHL',
            allPermissions: []
        };

        vm.data = {
            email: null,
            password: null
        };

        vm.init = init;
        vm.toggleSelection = toggleSelection;
        vm.matchPermissions = matchPermissions;

        vm.modal = {
            update: function() {
                RolesService.updateRole(vm.newRole.ediRoleId, vm.newRole)
                    .then(function(response){
                        vm.newRole.result = "updated";
                        $uibModalInstance.close(vm.newRole);
                    })
                    .catch(function(response){
                        vm.newRole.result = "error";
                        $uibModalInstance.close(vm.newRole);
                    });
            },
            delete: function(){
                RolesService.deleteRole(passRole.ediRoleId)
                .then(function(response){
                    vm.newRole.result = 'deleted';
                    $uibModalInstance.close(vm.newRole);
                })
                .catch(function(response){
                    vm.newRole.result = 'error';
                    $uibModalInstance.close(vm.newRole);
                });
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };

        function init(){
            RolesService.getPermissions()
                .then(function(response) {
                    vm.allPermissions = response.data._embedded.ediPermissions;
                    RolesService.getPermissionsByRoleId(passRole.ediRoleId)
                    .then(function(response) {
                        for(var i=0; i<response.data._embedded.ediPermissions.length; i++){
                            vm.newRole.allPermissions.push(response.data._embedded.ediPermissions[i].ediPermissionId);
                        }
                        vm.matchPermissions(vm.allPermissions, vm.newRole.allPermissions);
                    })
                    .catch(function(response) {
                        AlertService.error($translate.instant("roles.getPermissionsError"));
                    });
                })
                .catch(function(response) {
                    AlertService.error($translate.instant("roles.getPermissionsError"));
                });
        }

        function matchPermissions(allPermissions, rolePermissions){
            var i;
            var j;
            for (i = 0; i <  allPermissions.length; i++) {
                vm.allPermissions[i].checked = false;
                for (j = 0; j <  rolePermissions.length; j++){
                    if(allPermissions[i].ediPermissionId == rolePermissions[j]){
                        vm.allPermissions[i].checked = true;
                    }
                }
            }
        }

        function toggleSelection(event,index,permission){
            if (event.target.checked){
                vm.newRole.allPermissions.push(permission.ediPermissionId);
            }
            else{
                var i;
                for (i = 0; i <  vm.newRole.allPermissions.length; ++i) {
                    if( vm.newRole.allPermissions[i] ==  vm.allPermissions[index].ediPermissionId)
                        vm.newRole.allPermissions.splice(i, 1);
                }
            }
        }

        vm.init();
    }
})();
