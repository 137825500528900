(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('clientsPanel', {
                parent: 'application',
                url: '/clientsPanel',
                views: {
                    'content@application': {
                        templateUrl: 'app/views/clients/clientsPanel.html'
                    }
                },
                redirectTo: 'clients',
            })
            .state('clients', {
                parent: 'clientsPanel',
                url: '/clients?size&page&email&clientNumber&clientName',
                params: {
                    size: '25',
                    page: '0',
                    email: null,
                    clientNumber: null,
                    clientName: null
                },
                data: {
                    pageTitle: 'global.menu.clients'
                },
                views: {
                    'innerContent@clientsPanel': {
                        templateUrl: 'app/views/clients/clients/clients.html',
                        controller: 'ClientsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('clients');
                        return $translate.refresh();
                    }]
                }
            }).state('client', {
                parent: 'clientsPanel',
                url: '/client?clientNumber',
                params: {
                    clientNumber: null
                },
                data: {
                    pageTitle: 'global.menu.client'
                },
                views: {
                    'innerContent@clientsPanel': {
                        templateUrl: 'app/views/clients/client/client.html',
                        controller: 'ClientController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('clients');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
