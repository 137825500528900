(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PendingCasesController', PendingCasesController);

    PendingCasesController.$inject = ['PendingCasesService', 'AlertService', '$uibModal', 'UtilService', 'DocumentsService', '$translate'];

    function PendingCasesController(PendingCasesService, AlertService, $uibModal, UtilService, DocumentsService, $translate) {
        var vm = this;

        //Declare variables
        vm.usersToBeUpdated = [];
        vm.documentsWithoutClientToBeUpdated = [];
        vm.documentsOfDebtorsToBeUpdated = [];
        vm.users = [];
        vm.documentsWithoutClient = [];
        vm.documentsOfDebtors = [];
        vm.checkAllUsersButton = true;
        vm.checkAllDocumentsWithoutClientButton = true;
        vm.checkAllDocumentsOfDebtorsButton = true;

        //Declare functions
        vm.init = init;
        vm.checkAllDocumentsWithoutClient = checkAllDocumentsWithoutClient;
        vm.checkAllDocumentsOfDebtors = checkAllDocumentsOfDebtors;
        vm.checkAllUsers = checkAllUsers;
        vm.denyDocumentsWithoutClient = denyDocumentsWithoutClient;
        vm.denyDocumentsOfDebtors = denyDocumentsOfDebtors;
        vm.acceptDocumentsWithoutClient = acceptDocumentsWithoutClient;
        vm.acceptDocumentsOfDebtors = acceptDocumentsOfDebtors;
        vm.denyUsers = denyUsers;
        vm.acceptUsers = acceptUsers;
        vm.usersCheckboxClick = usersCheckboxClick;
        vm.documentsWithoutClientCheckboxClick = documentsWithoutClientCheckboxClick;
        vm.documentsOfDebtorsCheckboxClick = documentsOfDebtorsCheckboxClick;
        vm.checkOrUncheckText = checkOrUncheckText;
        vm.getNameByClientNumber = getNameByClientNumber;
        vm.downloadPDF = downloadPDF;

        function checkOrUncheckText(condition) {
            return condition ? $translate.instant("pendingCases.pendingCases.selectAll") : $translate.instant("pendingCases.pendingCases.clearAll");
        }

        // add or remove user form usersToBeUpdated table
        function usersCheckboxClick(event, index) {
            event.stopPropagation();
            if (event.target.checked) {
                vm.usersToBeUpdated.push(index);
            } else {
                var i;
                for (i = 0; i < vm.usersToBeUpdated.length; ++i) {
                    if (vm.usersToBeUpdated[i] === index) {
                        vm.usersToBeUpdated.splice(i, 1);
                    }
                }
            }
        }

        // add or remove document form documentsWithoutClientToBeUpdated table
        function documentsWithoutClientCheckboxClick(event, index) {
            event.stopPropagation();
            if (event.target.checked) {
                vm.documentsWithoutClientToBeUpdated.push(vm.documentsWithoutClient[index].transactionId);
            } else {
                var i;
                for (i = 0; i < vm.documentsWithoutClientToBeUpdated.length; ++i) {
                    if (vm.documentsWithoutClientToBeUpdated[i] === vm.documentsWithoutClient[index].transactionId) {
                        vm.documentsWithoutClientToBeUpdated.splice(i, 1);
                    }
                }
            }
        }

        // add or remove document form documentsOfDebtorsToBeUpdated table
        function documentsOfDebtorsCheckboxClick(event, index) {
            event.stopPropagation();
            if (event.target.checked) {
                vm.documentsOfDebtorsToBeUpdated.push(vm.documentsOfDebtors[index].transactionId);
            } else {
                var i;
                for (i = 0; i < vm.documentsOfDebtorsToBeUpdated.length; ++i) {
                    if (vm.documentsOfDebtorsToBeUpdated[i] === vm.documentsOfDebtors[index].transactionId) {
                        vm.documentsOfDebtorsToBeUpdated.splice(i, 1);
                    }
                }
            }
        }

        function acceptUsers() {
            if (vm.usersToBeUpdated.length !== 0) { //if there is at least one selected user
                var usersList = [];

                var proceed = true;
                angular.forEach(vm.usersToBeUpdated, function(index) { //vm.users array indexes stored in vm.usersToBeUpdated
                    var user = vm.users[index];
                    if (!UtilService.isFilled(user.clientName) || !UtilService.isFilled(user.sapNumber)) {
                        AlertService.error($translate.instant("pendingCases.pendingCases.someRegistrationFailed"));
                        proceed = false; //do not proceed with accepting, client data input is missing
                    } else {
                        usersList.push({
                            'userId': user.userId,
                            'ipAddress': user.ipAddress,
                            'email': user.email,
                            'nip': user.nip,
                            'invoiceNumber': user.invoiceNumber,
                            'invoiceValue': user.invoiceValue,
                            'sapNumber': user.sapNumber + "", //convert to string
                            'clientName': user.clientName,
                            'payerNumber': user.payerNumber
                        });
                    }
                });

                if (proceed) {
                    PendingCasesService.usersAccepted(usersList)
                        .then(function(response) {
                            if (response.data.failed != null && Object.keys(response.data.failed).length > 0) {
                                var existingUsers = "";
                                var otherMsg = "";
                                for (var key in response.data.failed) {
                                    if (response.data.failed.hasOwnProperty(key)) {
                                        var message = response.data.failed[key].message;
                                        if (message == "pending.user.exist") {
                                            existingUsers += " " + response.data.failed[key].atrList[0];
                                        } else {
                                            otherMsg += "\n" + message;
                                        }
                                    }
                                }
                                var errors = "";
                                if (existingUsers !== "") {
                                    errors = $translate.instant("pendingCases.pendingCases.userExists") + existingUsers;
                                }
                                if (otherMsg !== "") {
                                    errors += otherMsg;
                                }

                                if (errors !== "") {
                                    AlertService.error(errors);
                                } else {
                                    AlertService.error($translate.instant("pendingCases.pendingCases.failedAcceptSomeUsers"));
                                }
                            } else {
                                AlertService.success($translate.instant("pendingCases.pendingCases.successAcceptMarkedUsers"));
                            }
                        })
                        .catch(function(response) {
                            AlertService.error($translate.instant("pendingCases.pendingCases.failedAcceptMarkedUsers"));
                        })
                        .finally(function(response) {
                            getUsers();
                        });
                }
            }
        }

        function denyUsers(size) {
            if (vm.usersToBeUpdated.length !== 0) { //if there is at least one selected user
                var modalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'app/views/pending-cases/rejection-modal/rejection.modal.html',
                    controller: 'RejectionController',
                    controllerAs: 'vm',
                    size: size
                });

                modalInstance.result.then(function(go) {
                    if (go.action) {
                        var usersList = [];

                        angular.forEach(vm.usersToBeUpdated, function(index) { //vm.users array indexes stored in vm.usersToBeUpdated
                            usersList.push({
                                'userId': vm.users[index].userId,
                                'rejectReason': go.text
                            });
                        });

                        PendingCasesService.usersDenied(usersList)
                            .then(function(response) {
                                if (response.data.failed == null || Object.keys(response.data.failed).length === 0) {
                                    AlertService.success($translate.instant("pendingCases.pendingCases.successDeniedMarkedUsers"));
                                } else {
                                    AlertService.error($translate.instant("pendingCases.pendingCases.failedDenySomeUsers"));
                                }
                            })
                            .catch(function(response) {
                                AlertService.error($translate.instant("pendingCases.pendingCases.failedDenyMarkedUsers"));
                            })
                            .finally(function(response) {
                                getUsers();
                            });
                    }
                });
            }
        }

        function acceptDocumentsWithoutClient() {
            if(vm.documentsWithoutClientToBeUpdated.length !== 0) {
                PendingCasesService.documentsWithoutClientAccepted(vm.documentsWithoutClientToBeUpdated)
                    .then(function(response) {
                        if (response.data.failed != null && Object.keys(response.data.failed).length !== 0) {
                            var noClientDocs = "";
                            for (var key in response.data.failed) {
                                if (response.data.failed.hasOwnProperty(key)) {
                                    noClientDocs += " " + response.data.failed[key].message.split(" ").splice(-1);
                                } else {
                                    AlertService.error($translate.instant("pendingCases.pendingCases.failedAcceptMarkedInvoices"));
                                }
                            }
                            if (noClientDocs !== "") {
                                var message = $translate.instant("pendingCases.pendingCases.missingClients");
                                AlertService.error(message + noClientDocs);
                            }
                        } else {
                            AlertService.success($translate.instant("pendingCases.pendingCases.acceptedInvoices"));
                        }
                    })
                    .catch(function(response) {
                        AlertService.error($translate.instant("pendingCases.pendingCases.failedAcceptMarkedInvoices"));
                    })
                    .finally(function(response) {
                        getDocumentsWithoutClient();
                    });
            }
        }

        function denyDocumentsWithoutClient() {
            if(vm.documentsWithoutClientToBeUpdated.length !== 0) {
                PendingCasesService.documentsWithoutClientDenied(vm.documentsWithoutClientToBeUpdated)
                    .then(function(response) {
                        if (response.data.failed == null || Object.keys(response.data.failed).length === 0) {
                            AlertService.success($translate.instant("pendingCases.pendingCases.successDenyMarkedInvoices"));
                        } else {
                            AlertService.error($translate.instant("pendingCases.pendingCases.failedDenySomeInvoices"));
                        }
                    })
                    .catch(function(response) {
                        AlertService.error($translate.instant("pendingCases.pendingCases.failedDenyMarkedInvoices"));
                    })
                    .finally(function(response) {
                        getDocumentsWithoutClient();
                    });
            }
        }

        function acceptDocumentsOfDebtors() {
            if(vm.documentsOfDebtorsToBeUpdated.length !== 0) {
                PendingCasesService.documentsOfDebtorsAccepted(vm.documentsOfDebtorsToBeUpdated)
                    .then(function(response) {
                        if (response.data.failed != null && Object.keys(response.data.failed).length !== 0) {
                            AlertService.error($translate.instant("pendingCases.pendingCases.failedAcceptMarkedInvoices"));
                        } else {
                            AlertService.success($translate.instant("pendingCases.pendingCases.acceptedInvoices"));
                        }
                    })
                    .catch(function(response) {
                        AlertService.error($translate.instant("pendingCases.pendingCases.failedAcceptMarkedInvoices"));
                    })
                    .finally(function(response) {
                        getDocumentsOfDebtors();
                    });
            }
        }

        function denyDocumentsOfDebtors() {
            if(vm.documentsOfDebtorsToBeUpdated.length !== 0) {
                PendingCasesService.documentsOfDebtorsDenied(vm.documentsOfDebtorsToBeUpdated)
                    .then(function(response) {
                        if (response.data.failed == null || Object.keys(response.data.failed).length === 0) {
                            AlertService.success($translate.instant("pendingCases.pendingCases.successDenyMarkedInvoices"));
                        } else {
                            AlertService.error($translate.instant("pendingCases.pendingCases.failedDenySomeInvoices"));
                        }
                    })
                    .catch(function(response) {
                        AlertService.error($translate.instant("pendingCases.pendingCases.failedDenyMarkedInvoices"));
                    })
                    .finally(function(response) {
                        getDocumentsOfDebtors();
                    });
            }
        }

        function checkAllUsers() {
            vm.usersToBeUpdated = []; //reset selection
            if(vm.checkAllUsersButton) { //select all
                setUserCheckboxes(true); 
                fillSelectedUsersArray();
            } else {
                setUserCheckboxes(false);
            }
            vm.checkAllUsersButton = !vm.checkAllUsersButton;
        }

        function setUserCheckboxes(checked){
            var i;
            for (i = 0; i < vm.users.length; ++i) {
                vm.users[i].selected = checked;
            }
        }
        
        function fillSelectedUsersArray(){
            var i;
            for (i = 0; i < vm.users.length; ++i) {
                vm.usersToBeUpdated.push(i);
            }
        }

        function checkAllDocumentsWithoutClient() {
            vm.documentsWithoutClientToBeUpdated = []; //reset selection
            if(vm.checkAllDocumentsWithoutClientButton) {
                selectAllDocs(vm.documentsWithoutClient, vm.documentsWithoutClientToBeUpdated);
            } else {
                clearAllDocs(vm.documentsWithoutClient);
            }
            vm.checkAllDocumentsWithoutClientButton = !vm.checkAllDocumentsWithoutClientButton;
        }

        function checkAllDocumentsOfDebtors() {
            vm.documentsOfDebtorsToBeUpdated = []; //reset selection
            if(vm.checkAllDocumentsOfDebtorsButton) {
                selectAllDocs(vm.documentsOfDebtors, vm.documentsOfDebtorsToBeUpdated);
            } else {
                clearAllDocs(vm.documentsOfDebtors);
            }
            vm.checkAllDocumentsOfDebtorsButton = !vm.checkAllDocumentsOfDebtorsButton;
        }

        function selectAllDocs(sourceArray, selectedArray){
            angular.forEach(sourceArray, function(item) {
                item.selected = true;
                selectedArray.push(item.transactionId);
            });
        }

        function clearAllDocs(sourceArray){
            angular.forEach(sourceArray, function(item) {
                item.selected = false;
            });
        }

        function getNameByClientNumber(event, index, clientNumber) {
            if (clientNumber != null && (clientNumber + "").length === 8) {
                PendingCasesService.getNameByClientNumber(clientNumber + "")
                    .then(function(response) {
                        if(response.data != null) {
                            vm.users[index].clientName = response.data.name;
                        }
                    })
                    .catch(function(response) {
                        AlertService.error($translate.instant("pendingCases.pendingCases.clientNotFound") + clientNumber);
                    });
            }
        }

        function getDocumentsWithoutClient() {
            PendingCasesService.getDocumentsWithoutClient()
                .then(function(response) {
                    vm.documentsWithoutClient = response.data;
                })
                .catch(function(response) {
                    AlertService.error($translate.instant("pendingCases.pendingCases.getDocumentsFail"));
                }).finally(function(response) {
                    vm.documentsWithoutClientToBeUpdated = []; //reset selection
                    vm.checkAllDocumentsWithoutClientButton = true;
                });
        }

        function getDocumentsOfDebtors() {
            PendingCasesService.getDocumentsOfDebtors()
                .then(function(response) {
                    vm.documentsOfDebtors = response.data;
                })
                .catch(function(response) {
                    AlertService.error($translate.instant("pendingCases.pendingCases.getDocumentsOfDebtorsFail"));
                }).finally(function(response) {
                    vm.documentsOfDebtorsToBeUpdated = []; //reset selection
                    vm.checkAllDocumentsOfDebtorsButton = true;
                });
        }

        function getUsers() {
            PendingCasesService.getUsers()
                .then(function(response) {
                    angular.forEach(response.data, function(item) {
                        item.sapNumber = parseInt(item.sapNumber);
                    });
                    vm.users = response.data;
                })
                .catch(function(response) {
                    AlertService.error($translate.instant("pendingCases.pendingCases.getUsersFail"));
                }).finally(function(response) {
                    vm.usersToBeUpdated = []; //reset selection
                    vm.checkAllUsersButton = true;
                });
        }

        function downloadPDF(ediDocId) {
            //            PendingCasesService.getDocument(ediDocId)
            //                .then(function(response) {
            //                    var date = new Date();
            //                    FileSaver.saveAs(new Blob([response.data], { type: "application/pdf" }),
            //                        "Document_" + (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()) + ".pdf");
            //                    if (Object.keys(response.data.failed).length != 0) {
            //                        AlertService.error('Nie udało się pobrać dokumentu.');
            //                    }
            //                    vm.getCorrections();
            //                })
            //                .catch(function(response) {
            //                    AlertService.error('Nie udało się pobrać dokumentu.');
            //                    vm.getCorrections();
            //                })
            //                .finally(function(response) {
            //                    //vm.getCorrections();
            //                });

            var url = DocumentsService.getPdfURL(ediDocId, null, null);
            window.open(url, '_blank');
        }

        function init() {
            getDocumentsWithoutClient();
            getDocumentsOfDebtors();
            getUsers();
        }

        //Init controller
        vm.init();
    }
})();
