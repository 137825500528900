(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('UtilService', UtilService);

    UtilService.$inject = ['$http'];

    function UtilService($http) {
        var Srvc = {
            get: get,
            getNIPPattern: getNIPPattern,
            isFilled: isFilled,
            nullsToEmptyStrings: nullsToEmptyStrings,
            emptyStringsToNulls: emptyStringsToNulls,
            starsToPercents: starsToPercents
        };

        return Srvc;

        function get(from, what, filter, data) {
            return $http({
                method: 'GET',
                url: removeCacheBuster(from._links[what].href),
                params: filter,
                data: data
            });
        }

        function getNIPPattern()/*(legalEntity)*/ {
            // if(legalEntity === 'BE') {
            //     return {maxlength: 15, regex: "^(BE)?(\\s)?[0-9]{4}\\.?[0-9]{3}\\.?[0-9]{3}$" };
            // } else {
            //     return {maxlength: 14, regex: "^[a-zA-Z0-9]{14}$" };
            // }
            return {minlength: 9, maxlength: 13, regex: "^[0-9]*$" };
        }

        function removeCacheBuster(url) {
            var tempUrl = url.replace(new RegExp('&amp;', 'g'), '&');
            var cacheBusterIndex = tempUrl.indexOf("cacheBuster");
            if (cacheBusterIndex != -1) {
                var nextAmpersandIndex = tempUrl.indexOf("&", cacheBusterIndex);

                if (nextAmpersandIndex != -1)
                    tempUrl = tempUrl.substr(0, cacheBusterIndex) + tempUrl.substr(nextAmpersandIndex, tempUrl.length);
                else
                    tempUrl = tempUrl.substr(0, cacheBusterIndex);
            }
            return tempUrl;
        }

        function isFilled(object) {
            if (object != null && object !== '' && object === object)
                return true;
            if (typeof object === 'object') {
                for (var param in object) {
                    if (isFilled(object[param]))
                        return true;
                }
            }
            return false;
        }

        function nullsToEmptyStrings(filter) {
            var requestFilter = angular.copy(filter);

            for (var fieldIndex in requestFilter) {
                if (requestFilter[fieldIndex] == null) {
                    requestFilter[fieldIndex] = "";
                }
            }
            return requestFilter;
        }

        function emptyStringsToNulls(filter) {
            var requestFilter = angular.copy(filter);

            for (var fieldIndex in requestFilter) {
                if (requestFilter[fieldIndex] == "") {
                    requestFilter[fieldIndex] = null;
                }
            }
            return requestFilter;
        }

        function starsToPercents(filter) {
            var requestFilter = angular.copy(filter);

            for (var fieldIndex in requestFilter) {
                if (typeof requestFilter[fieldIndex] == "string")
                    requestFilter[fieldIndex] = requestFilter[fieldIndex].replace(/\*/g, '%');
                else if (requestFilter[fieldIndex] instanceof Array)
                    for (var element in requestFilter[fieldIndex])
                        requestFilter[fieldIndex][element] = requestFilter[fieldIndex][element].replace(/\*/g, '%');
            }
            return requestFilter;
        }
    }
})();
