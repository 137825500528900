(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('NewUserController', NewUserController);

    NewUserController.$inject = ['$uibModalInstance', '$translate', 'AlertService', 'UsersService'];

    function NewUserController($uibModalInstance, $translate, AlertService, UsersService) {
        var vm = this;
        vm.exists = null;

        vm.data = {
            email: null,
            password: null
        };

        vm.modal = {
            timeout: null,
            lookup: function() {
                if(vm.data.email != null) {
                    vm.exists = null;
                    clearTimeout(vm.modal.timeout);
                    vm.modal.timeout = setTimeout(function() {
                        UsersService.isEmailUniqueOtherSN(vm.data.email)
                            .then(function(response) {
                                if(response.data.unique === true) {
                                    vm.exists = false;
                                    AlertService.clear();
                                } else {
                                    vm.exists = true;
                                    AlertService.warning($translate.instant("users.newUser.existsInOtherSN"));
                                }
                            })
                            .catch(function(response) {
                                AlertService.error($translate.instant("users.errors.occurred"));
                            });
                    }, 500);
                }
            },
            add: function() {
                UsersService.isEmailUnique(vm.data.email)
                    .then(function(response){
                        if(!vm.exists) {
                            UsersService.checkNewUsersPasswordValid(vm.data.password)
                                .then(function(response){
                                    $uibModalInstance.close(vm.data);
                                })
                                .catch(function(response){
                                    var passwordChangeErrorText;
                                    switch (response.data.message) {
                                        case 1:
                                            passwordChangeErrorText = $translate.instant("users.newUser.error1");
                                            break;
                                        case 4:
                                            passwordChangeErrorText = $translate.instant("users.newUser.error4");
                                            break;
                                        case 5:
                                            passwordChangeErrorText = $translate.instant("users.newUser.error5");
                                            break;
                                        default:
                                            passwordChangeErrorText = $translate.instant("error.occurred");
                                    }
                                    AlertService.error(passwordChangeErrorText);
                                });
                        } else {
                            $uibModalInstance.close(vm.data);
                        }
                    })
                    .catch(function(response){
                        AlertService.error($translate.instant("users.newUser.exists"));
                    });
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };
    }
})();
