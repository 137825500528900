(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('clickToEdit', ['$timeout', '$compile', function($timeout, $compile) {
            return {
                require: 'ngModel',
                scope: {
                    model: '=ngModel',
                    type: '@type'
                },
                replace: true,
                transclude: false,
                // includes our template
                templateUrl: 'app/components/form/clickToEdit.html',
                link: function(scope, element, attrs, model) {
                    scope.editState = false;

                    // make a local ref so we can back out changes, this only happens once and persists
                    scope.element = element;

                    if (!String.prototype.startsWith) {
                        String.prototype.startsWith = function(searchString, position) {
                            position = position || 0;
                            return this.indexOf(searchString, position) === position;
                        };
                    }

                    var input = element.find("input");
                    for (var attr in attrs.$attr) {
                        if (attrs.$attr[attr].startsWith("data-")) {
                            input.attr(attrs.$attr[attr].replace("data-", ""), element.attr(attrs.$attr[attr]));
                        }
                    };
                    input.append($compile(input)(scope));
                    if( attr == "textarea" ){
                        input.remove();
                    } else {
                        var textarea = element.find("textarea");
                        textarea.remove();
                    }

                    // apply the changes to the real model
                    scope.save = function($event) {
                        scope.toggle($event);
                    };

                    // don't apply changes
                    scope.cancel = function($event) {
                        scope.model = scope.localModel;
                        scope.toggle($event);
                    }

                    scope.edit = function($event) {
                        scope.localModel = scope.model;
                        scope.toggle($event);
                    }

                    /*
                     * toggles the editState of our field
                     */
                    scope.toggle = function($event) {
                        scope.editState = !scope.editState;

                        /*
                         * a little hackish - find the "type" by class query
                         *
                         */
                        var x1 = scope.element.find('input');


                        /*
                         * could not figure out how to focus on the text field, needed $timout
                         * http://stackoverflow.com/questions/14833326/how-to-set-focus-on-input-field-in-angularjs
                         */
                        $timeout(function($event) {
                            // focus if in edit, blur if not. some IE will leave cursor without the blur
                            if (scope.editState) {
                                x1.focus();
                                x1.select();
                            } else
                                x1.blur();
                        }, 0);
                    }
                }
            }
        }]).directive('ngEnter', function() {
            return function(scope, element, attrs) {
                element.unbind("keypress keydown");
                element.bind("keypress keydown", function(event) {
                    if (event.which === 13) {
                        scope.$apply(function() {
                            scope.$eval(attrs.ngEnter);
                        });
                        event.preventDefault();
                    } else if (event.which === 27) {
                        scope.$apply(function() {
                            scope.$eval(attrs.ngEscape);
                        });
                        event.preventDefault();
                    }
                });
            };
        });

})();
