(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('ClientsController', ClientsController);

    ClientsController.$inject = ['$scope', '$state', '$stateParams', 'ClientsService', 'UtilService', 'Upload', '$rootScope', '$uibModal', 'AlertService', '$translate', 'Principal', 'API_URL'];

    function ClientsController($scope, $state, $stateParams, ClientsService, UtilService, Upload, $rootScope, $uibModal, AlertService, $translate, Principal, API_URL) {
        var vm = this;
        vm.clientsList = [];

        /*Declare variables*/
        vm.filter = {
            data: {
                size: 10,
                page: 0,
                email: "",
                clientNumber: "",
                clientName: ""
            },
            clear: function() {
                vm.filter.data.email = "";
                vm.filter.data.clientNumber = "";
                vm.filter.data.clientName = "";
            },
            save: function() {
                if (vm.data && vm.data.page)
                    vm.filter.data.page = vm.data.page.number;
                $state.transitionTo($state.current, vm.filter.data, {
                    location: true,
                    inherit: true,
                    notify: false
                });
            },
            read: function() {
                angular.copy($stateParams, vm.filter.data);
            }
        };
        vm.data = null;
        vm.actions = [];
        vm.wrongFile = function() {
            return $translate.instant("clients.clients.wrongFile");
        },
        vm.required = function() {
            return $translate.instant("clients.clients.required");
        },
        vm.notFound = function() {
            return $translate.instant("clients.clients.notFound");
        },
        vm.notFoundClient = function() {
            return $translate.instant("clients.clients.notFoundClient");
        },
        vm.wrong = function() {
            return $translate.instant("clients.clients.wrong");
        },
        vm.multipleAdd = function() {
            return $translate.instant("clients.clients.multipleAdd");
        },
        vm.clientExists = function() {
            return $translate.instant("clients.clients.clientExists");
        },
        vm.multiple = function() {
            return $translate.instant("clients.clients.multiple");
        },
        vm.yesOrNo = function(variable) {
            return variable ? $translate.instant("clients.newClient.yes") : $translate.instant("clients.newClient.no");

        },
        vm.yesOrNoeInvoiceAgreement = function() {
            // return entry.client.eInvoiceAgreement ? $translate.instant("clients.newClient.yes"):$translate.instant("clients.newClient.no");
        },
            /*Declare functions*/
            //Controller init
        vm.init = function() {
            vm.filter.read();
            if (UtilService.isFilled(vm.filter.data))
                vm.client.current();
        };

        //Navigating
        vm.nav = {
            client: function(client) {
                $state.go('client', client);
            }
        };

        //Getting clients
        vm.client = {
            //List clients
            current: function() {
                vm.filter.data.page = 0;

                ClientsService.listWithParams(vm.filter.data)
                    .then(vm.client.then);
            },
            //Go to the first page
            first: function() {
                UtilService.get(vm.data, 'first', null, null)
                    .then(vm.client.then);
            },
            //Go to the previous page
            previous: function() {
                UtilService.get(vm.data, 'prev', null, null)
                    .then(vm.client.then);
            },
            //Go to the next page
            next: function() {
                UtilService.get(vm.data, 'next', null, null)
                    .then(vm.client.then);
            },
            //Go to the last page
            last: function() {
                UtilService.get(vm.data, 'last', null, null)
                    .then(vm.client.then);
            },
            then: function(response) {

                //add 'BENELUXUI' to url if missing 
                if(response.data._links['first'])
                    response.data._links['first'].href = fixURL(response.data._links['first'].href);
                if(response.data._links['self'])
                    response.data._links['self'].href = fixURL(response.data._links['self'].href);
                if(response.data._links['next'])
                    response.data._links['next'].href = fixURL(response.data._links['next'].href);
                if(response.data._links['prev'])
                    response.data._links['prev'].href = fixURL(response.data._links['prev'].href);
                if(response.data._links['last'])
                    response.data._links['last'].href = fixURL(response.data._links['last'].href);

                //Set data to controller data
                vm.data = response.data;
                var nullData = true;
                if (response.data._embedded != null) {
                    switch (Principal.getCurrentEntity()) {
                        case 'BE':
                            if(response.data._embedded.beClientPortalEntryResources != null) {
                                vm.clientsList = response.data._embedded.beClientPortalEntryResources;
                                nullData = false;
                            }
                            break;
                        case 'NL':
                            if(response.data._embedded.nlClientPortalEntryResources != null) {
                                vm.clientsList = response.data._embedded.nlClientPortalEntryResources;
                                nullData = false;
                            }
                            break;
                        case 'EC':
                            if(response.data._embedded.ecClientPortalEntryResources != null) {
                                vm.clientsList = response.data._embedded.ecClientPortalEntryResources;
                                nullData = false;
                            }
                            break;
                    }
                }
                if(nullData) {
                    AlertService.error($translate.instant("clients.clients.clientNotFound"));
                } else {
                    AlertService.clear();
                }
                //Write state to URL
                vm.filter.save();
            },
            new: function() {
                var newClientModal = $uibModal.open({
                    templateUrl: 'app/views/clients/newClient/newClient.html',
                    controller: 'NewClientController',
                    controllerAs: 'vm',
                    size: 'lg'
                });

                newClientModal.result.then(function(newClient) {
                    ClientsService.create(newClient).then(function(response) {
                        vm.client.current();
                        AlertService.success($translate.instant("clients.clients.clientCreated"));
                    }).catch(function(response) {
                        AlertService.error($translate.instant("clients.clients.errorAdding"));
                    });
                });
            }
        };

        function fixURL(url) {
            if($rootScope.API_URL === API_URL.BE) {
                url = url.replace('https://billing.dhlparcel.nl', 'https://billing.dhlparcel.be');
            } else {
                url = url.replace('https://billing.dhlparcel.be', 'https://billing.dhlparcel.nl');
            }
            if( url != null && !(url.indexOf('BENELUXUI') !== -1) && ((url.indexOf('dev') !== -1) || (url.indexOf('qas') !== -1))) {
                return url.replace('edistrada2', 'BENELUXUI/edistrada2');
            } else {
                return url;
            }
        }

        vm.management = {
            file: null,
            progress: 0,
            status: '',
            message: '',
            errors: [],
            done: false,
            parse: function() {
                if (vm.management.file) {
                    vm.management.progress = 1;
                    vm.management.status = 'info';
                    vm.management.message = $translate.instant("clients.clients.sending");
                    vm.management.errors = [];
                    vm.management.done = false;
                    vm.actions = [];
                    Upload.http({
                        url: $rootScope.API_URL + 'api/beneluxClients/manage/parse',
                        data: vm.management.file,
                        headers: {
                            "Content-Type": "application/octet-stream"
                        }
                    }).then(function(response) {
                        vm.management.done = true;
                        vm.management.status = 'success';
                        vm.management.message = $translate.instant("clients.clients.finished");
                        response.data.forEach(function(action) {
                            vm.actions.push(action);
                        });
                    }, function(response) {
                        vm.management.done = true;
                        vm.management.status = 'danger';
                        vm.management.message = $translate.instant("clients.clients.errorInFile");
                        vm.management.errors = response.data;
                    },
                    function(event) {
                        vm.management.progress = Math.min(100, parseInt(100.0 *
                            event.loaded / event.total));
                        if (vm.management.progress == 100) {
                            vm.management.status = null;
                            vm.management.message = $translate.instant("clients.clients.processing");
                        }
                    });
                }
            },
            cancel: function() {
                vm.management.progress = 0;
                vm.management.status = '';
                vm.management.message = '';
                vm.management.done = false;
                vm.actions = [];
            },
            succeed: function() {
                vm.management.cancel();
                vm.management.status = 'succeeded';
            },
            fail: function() {
                vm.management.cancel();
                vm.management.status = 'failed';
            },
            manage: function() {
                ClientsService.manage(vm.actions)
                    .then(function(response) {
                        if (response.data == null || response.data.length === 0) {
                            vm.management.succeed();
                        } else {
                            vm.actions = [];
                            vm.management.status = 'danger';
                            vm.management.message = $translate.instant("clients.clients.notSaved");
                            AlertService.error($translate.instant("clients.clients.notCreatedInfo"));
                            response.data.forEach(function(action) {
                                vm.actions.push(action);
                            });
                        }
                    })
                    .catch(function(response) {
                        vm.management.fail();
                    });
            }
        };

        //Define a watch for file upload
        $scope.$watch('vm.management.file', function() {
            vm.management.parse();
        });

        //Init controller
        vm.init();

        //Position fix-table
        var fixed = $('#fixed'),
            underFixed = $('#under-fixed');
        $scope.$watch(
            function() {
                return fixed.outerHeight();
            },
            function(newValue) {
                underFixed.css('padding-top', newValue);
            });
    }
})();
