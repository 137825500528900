(function() {
    'use strict';

    angular
        .module('edistradadhluiApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngAnimate',
            'ngCacheBuster',
            'ngFileUpload',
            'ngFileSaver',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ngMaterial',
            'uuid',
            'ngLoadingSpinner'
        ])
        .run(run);

    run.$inject = ['EntityService', '$rootScope', 'stateHandler', 'translationHandler', 'Principal', 'DEBUG_INFO_ENABLED', 'VERSION', '$translate', '$location'];

    function run(EntityService, $rootScope, stateHandler, translationHandler, Principal, DEBUG_INFO_ENABLED, VERSION, $translate, $location) {
        var url = $location.absUrl();
        if(url.includes('billing.dhlparcel.be')) {
            EntityService.setUrlDomain('BE');
        } else if(url.includes('billing.dhlparcel.nl')) {
            EntityService.setUrlDomain('NL');
        } else {
            EntityService.setUrlDomain('EC');
        }

        stateHandler.initialize();
        translationHandler.initialize();
        $rootScope.Principal = Principal;
        $rootScope.DEBUG_INFO_ENABLED = DEBUG_INFO_ENABLED;
        $rootScope.VERSION = VERSION;
        angular.element(window).on("resize", function() {
            $rootScope.$apply();
        });

        $rootScope.$translate = $translate;
        $rootScope.$conditionalTranslate = function(condition, phrase) {
            if (condition)
                return $translate.instant(phrase);
            else return "";
        };

        //        PDFViewerApplication.isViewerEmbedded = true; //This prevents PDF.js to change view title
        //        PDFViewerApplication.postMessageTransfers = false; //This prevents PDF.js to log unnecesarry things
    }
})();
