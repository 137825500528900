(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('disputeForm', {
            parent: 'pdfView',
            url: '/disputeForm',
            views: {
                'content@application': {
                    templateUrl: 'app/views/pdfView/disputeForm/disputeForm.html',
                    controller: 'DisputeFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pdfView');
                    return $translate.refresh();
                }],
                uuid: ['$stateParams', function($stateParams) {
                    return $stateParams.uuid;
                }]
            }
        });
    }
})();
