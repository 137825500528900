(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('DisputeFormController', DisputeFormController);

    DisputeFormController.$inject = [ '$scope', '$state', '$stateParams', '$location', 'Principal', 'DocumentsService', '$translate'];

    function DisputeFormController($scope, $state, $stateParams, $location, Principal, DocumentsService, $translate) {
        var vm = this;
        vm.translate = $translate.instant;

        // Declare variables
        vm.data = {
            name: null,
            email: null,
            retypeEmail: null,
            accountNumber: null,
            invoiceNumber: null,
            commentEntries: []
        }
        vm.originalEmail = null;
        vm.emailChanged = false;
        vm.disputeFormSuccessText = null;
        vm.disputeFormErrorText = null;

        // Declare functions
        vm.init = init;
        vm.downloadInitialFormData = downloadInitialFormData;
        vm.setInitialFormData = setInitialFormData;
        vm.submit = submit;
        vm.toggleRetypeEmailField = toggleRetypeEmailField;
        vm.addCommentField = addCommentField;
        vm.addFileText = addFileText;

        // Init functions
        function init() {
            if (!Principal.isAuthenticated()) {
                $state.go('accessdenied').then(function() {
                    $state.go('login');
                });
            }
            if ($stateParams.uuid == null) {
                $state.go('documents');
            }
            downloadInitialFormData();
            // Initialise first comment entry to be visible from the get-go
            vm.data.commentEntries = [{shipmentNumber: "", comment: "", attachment: null}];
        }

        function downloadInitialFormData() {
            DocumentsService.getDisputeFormData($stateParams.uuid)
                .then(function(response) {
                    vm.disputeFormErrorText = null;
                    setInitialFormData(response.data);
                    vm.originalEmail = vm.data.email;
                })
                .catch(function(response) {
                    vm.disputeFormSuccessText = null;
                    vm.disputeFormErrorText = vm.translate('pdfView.submitDispute.downloadError');
                });
        }

        function setInitialFormData(data) {
            vm.data.name = data.name;
            vm.data.email = data.email;
            vm.data.accountNumber = data.accountNumber;
            vm.data.invoiceNumber = data.invoiceNumber;
        }

        function submit() {
            DocumentsService.submitDispute(vm.data)
                .then(function(response) {
                    vm.disputeFormSuccessText = vm.translate('pdfView.submitDispute.success');
                    vm.disputeFormErrorText = null;
                    document.getElementById("button").disabled = true;
                }).catch(function(response) {
                    vm.disputeFormSuccessText = null;
                    vm.disputeFormErrorText = vm.translate('pdfView.submitDispute.error');
                });
        }

        function toggleRetypeEmailField() {
            vm.emailChanged = vm.data.email !== vm.originalEmail ? true : false;
        }

        function addCommentField() {
            vm.data.commentEntries.push({
                shipmentNumber: "",
                comment: "",
                attachment: null
            });
        }

        function addFileText($event) {
            var inputFile = $event.target
            var label = inputFile.previousElementSibling
            inputFile.addEventListener("change", function(){
                if (inputFile.files.length > 0) {
                    label.textContent = inputFile.files[0].name
                }else{
                    label.textContent = "No file"
                }
            });
        }

        // Init controller
        vm.init();
    }
})();
