(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$sce', '$scope', '$state', '$stateParams', 'User', 'Principal', 'JhiLanguageService', 'PasswordResetInit', '$filter', 'UsersService', 'AlertService', '$translate'];

    function SettingsController($sce, $scope, $state, $stateParams, User, Principal, JhiLanguageService, PasswordResetInit, $filter, UsersService, AlertService, $translate) {
        var vm = this;

        //Declare variables
        vm.filter = null;
        vm.data = {
            user: {
                user: {
                    passwordExpireDate: null,
                    accountLanguage: null,
                    language: 'Polski'
                },
                lastLoginFailure: null,
                onePageDocumentShown: true
            }
        };
        vm.language = "Polski";
        vm.language1 = null;
        vm.passwordChangeSuccessText = null;
        vm.passwordChangeErrorText = null;
        vm.languageChangeSuccessText = null;
        vm.languageChangeErrorText = null;
        vm.getUserErrorText = null;
        vm.lastLoginFail = null;
        //TODO for now needs refresh to function
        vm.safePassword = $sce.trustAsHtml($translate.instant('settings.toolTips.safePassword'));
        vm.documentSettingChangeErrorText = null;
        vm.documentSettingChangeSuccessText = null;
        vm.userId = null;
        vm.none = $translate.instant('settings.none');
        //Declare functions
        vm.init = init;
        vm.setLanguage = setLanguage;
        vm.getUser = getUser;
        vm.resetPassword = resetPassword;
        vm.changeDocumentSetting = changeDocumentSetting;
        vm.clientNav = clientNav;
        vm.passwordTooltip = passwordTooltip;

        function passwordTooltip() {
            return $sce.trustAsHtml($translate.instant('settings.toolTips.safePassword'));
        };


        //Init functions
        function init() {
            if (Principal.isAuthenticated()) {
                vm.userId = Principal.getProperty('userId');
                vm.getUser();
            }
        }

        function setLanguage() {
            vm.language1 = $filter('findKeyFromLanguage')(vm.language);
            vm.data.user.user.accountLanguage = vm.language1;


            UsersService.postChanges(vm.data.user.user.ediUserId, vm.data.user.user.accountLanguage).then(function(response) {
                vm.languageChangeSuccessText = $translate.instant('settings.toolTips.languageChangeSuccess');
                vm.languageChangeErrorText = null;
            }).catch(function(response) {
                vm.languageChangeSuccessText = null;
                vm.languageChangeErrorText = $translate.instant('error.occurred');
            });
        }

        function getUser() {
            if (Principal.isAuthenticated()) {
                UsersService.getDetails(Principal.getProperty('userId')).then(function(response) {
                    vm.data.user = response.data;
                    vm.language = $filter('findLanguageFromKey')($filter('lowercase')(response.data.user.accountLanguage));
                }).catch(function(response) {
                    vm.data = {
                        user: {
                            user: {
                                passwordExpireDate: null,
                                accountLanguage: null,
                                language: 'Polski'
                            },
                            lastLoginFailure: null,
                            onePageDocumentShown: true
                        }
                    };
                    vm.getUserErrorText = $translate.instant('error.occurred');
                });
            } else {
                vm.data = {
                    user: {
                        user: {
                            passwordExpireDate: null,
                            accountLanguage: null,
                            language: 'Polski'
                        }
                    },
                    lastLoginFailure: null,
                    onePageDocumentShown: true
                };
                vm.getUserErrorText = $translate.instant('error.occurred');
            }
        }

        function resetPassword(user) {
            if (Principal.isAuthenticated()) {
                PasswordResetInit.resetPassword(vm.data.user.user.ediUserId, user).then(function(response) {
                    vm.passwordChangeSuccessText = $translate.instant('settings.toolTips.passwordChangeSuccess');
                    vm.passwordChangeErrorText = null;
                }).catch(function(response) {
                    vm.passwordChangeSuccessText = null;
                    switch (response.data.message) {
                        case 1:
                            vm.passwordChangeErrorText = $translate.instant('settings.toolTips.noFieldsEmpty');
                            break;
                        case 2:
                            vm.passwordChangeErrorText = $translate.instant('settings.toolTips.invalidCurrentPassword');
                            break;
                        case 3:
                            vm.passwordChangeErrorText = $translate.instant('global.messages.error.dontmatch')
                            break;
                        case 4:
                            vm.passwordChangeErrorText = $translate.instant('settings.messages.error.minlength');
                            break;
                        case 5:
                            vm.passwordChangeErrorText = $translate.instant('settings.toolTips.passwordValidationDescription');
                            break;
                        case 6:
                            vm.passwordChangeErrorText = $translate.instant('settings.toolTips.passwordNoRepeat');
                            break;
                        default:
                            vm.passwordChangeErrorText = $translate.instant('error.occurred');
                    }
                });
            } else {
                vm.passwordChangeErrorText = $translate.instant('error.relog');
            }
        }

        function changeDocumentSetting(documentSetting) {
            if (documentSetting != null && vm.data.user.user.ediUserId != null) {
                UsersService.setOnePagePdf(vm.data.user.user.ediUserId, documentSetting).then(function(response) {
                    vm.documentSettingChangeSuccessText = $translate.instant('settings.toolTips.changeSuccessful');
                    vm.documentSettingChangeErrorText = null;
                }).catch(function(response) {
                    vm.documentSettingChangeSuccessText = null;
                    vm.documentSettingChangeErrorText = $translate.instant('error.occurred');
                });
            } else {
                vm.documentSettingChangeSuccessText = null;
                vm.documentSettingChangeErrorText = $translate.instant('error.occurred');
            }

        }

        function clientNav(client) {
            $state.go('client', client);
        }


        //Init controller
        vm.init();
    }
})();
