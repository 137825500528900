(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$rootScope', '$translate'];

    function ContactController($rootScope, $translate) {
        var vm = this;

        vm.filter = null;
        vm.data = null;
        vm.language = function() {
            return $translate.use();
        };

        vm.getTranslation = getTranslation;
        vm.getUrl = getUrl;

        function getTranslation(translation) {
            return $translate.instant("contact." + translation);
        }

        function getUrl(translation) {
            return $translate.instant("contact." + translation + "." + $rootScope.API_DOMAIN);
        }

    }
})();
