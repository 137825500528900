(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('Account', Account);

    Account.$inject = ['$rootScope', '$resource', '$http'];

    function Account($rootScope, $resource, $http) {
        var service = {
            get: get
        };

        function get() {
            return $http({
                method: 'GET',
                url: $rootScope.API_URL + 'api/session'
            });
        }

        // var apiUrl = $rootScope.API_URL;
        // console.log('Account ai url = ' + apiUrl);
        // var service = $resource(apiUrl + 'api/session', {}, {
        //     'get': {
        //         method: 'GET',
        //         params: {},
        //         isArray: false,
        //         interceptor: {
        //             response: function(response) {
        //                 // expose response
        //                 return response;
        //             }
        //         }
        //     }
        // });

        return service;
    }
})();
